import React from "react"

const useHasMouseMoved = () => {
  const [hasMouseMoved, setHasMouseMoved] = React.useState(false)

  React.useEffect(() => {
    if (hasMouseMoved) return

    const updateMousePosition = () => setHasMouseMoved(true)

    window.addEventListener("mousemove", updateMousePosition)
    window.addEventListener("touchmove", updateMousePosition)

    return () => {
      window.removeEventListener("mousemove", updateMousePosition)
      window.removeEventListener("touchmove", updateMousePosition)
    }
  }, [])
  return hasMouseMoved
}

export default useHasMouseMoved
