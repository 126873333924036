import parse from "html-react-parser"

export const getMenuLink = menu => {
  let link = {}

  if (menu) {
    const { slug, custom_link, external_link } = menu
    const formattedCustomLink = custom_link?.replace(/ /g, "")

    if (formattedCustomLink) {
      if (formattedCustomLink.includes("http") || external_link) {
        link = {
          href: formattedCustomLink,
          target: "_blank",
        }
      } else {
        link = {
          to: formattedCustomLink,
        }
      }
    } else if (slug) {
      link = {
        to: `/${slug}/`,
      }
    }
  }

  return link
}

export const splitArray = (array, size) => {
  const arr = []
  array?.forEach((venue, i) => {
    if (i % parseInt(size) === 0) {
      arr.push([venue])
    } else {
      arr[arr.length - 1].push(venue)
    }
  })

  return arr
}

export const parseHTML = htmlString => {
  if (typeof htmlString === "string" && htmlString?.length > 0) {
    return parse(htmlString)
  }

  return null
}

// funtion to break a string into two lines - adds a <br /> tag before the last word
export const breakString = string => {
  if (!string) return null

  const words = string.split(" ")

  if (words.length < 2) return string

  const lastWord = "<br />" + words.pop()
  const formattedString = words.join(" ") + lastWord

  return formattedString
}

export const getCtaBtnClass = ctaTheme => {
  switch (ctaTheme) {
    case "black":
      return "button button-black"
    default:
      return "button"
  }
}

export const scrollToElement = (element, offset = 1) => {
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  typeof window !== "undefined" &&
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    })
}

export const formTracking = ({ event_tracking, form_name }) => {
  // tracking event
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "formSubmit",
    formType: "form-" + event_tracking,
    formId: "form-" + event_tracking,
    formName: form_name,
    formLabel: form_name,
  })
}
