import React from "react"
import "./BurgerMenu.scss"
import { Offcanvas } from "react-bootstrap"
import iconClose from "../../images/icons/x-mark.svg"
import { graphql, Link, useStaticQuery } from "gatsby"
import useCompanyInfo from "../../hooks/useCompanyInfo"
import CTALink from "../elements/CTALink"
import { enquireURL } from "../../common/utils/urls"

const BurgerMenu = ({ showModal, setShowModal }) => {
  const { address, email, phone } = useCompanyInfo()

  const data = useStaticQuery(graphql`
    query MyQuery {
      allStrapiGlobalModule {
        nodes {
          burger_menus {
            ...componentCTAFragment
          }
        }
      }
    }
  `)

  const { burger_menus } = data.allStrapiGlobalModule.nodes[0]

  return (
    <Offcanvas
      className="burger-menu-wrap"
      placement="top"
      show={showModal}
      onHide={() => setShowModal(false)}
    >
      <Offcanvas.Body className="burger-menu-section">
        <div className="burger-header">
          <img
            src={iconClose}
            alt="close icon"
            onClick={() => setShowModal(false)}
          />
        </div>
        <div className="burger-body">
          <div className="left-section">
            {burger_menus.map((cta, index) => (
              <CTALink
                className="burger-item"
                cta={cta}
                onClick={() => setShowModal(false)}
              />
            ))}
          </div>
          <div className="right-section">
            <h4 className="title">contact us</h4>
            <div className="footer-info">
              <a className="phone" href={`tel:${phone}`}>
                {phone}
              </a>
              <Link className="email" to={enquireURL}>
                {email}
              </Link>
              <p className="address">{address}</p>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default BurgerMenu
