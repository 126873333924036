import { AnimatePresence } from "framer-motion"
import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import CheckWebpFeature from "./src/common/ggfx-client/module/webp-checker"
import Footer from "./src/components/Footer/Footer"
import Header from "./src/components/Header/Header"

CheckWebpFeature()

export const wrapPageElement = ({ element, props }) => {
  const pageData =
    props.data?.strapiPage || props.data?.strapiVenue || props.data?.strapiStory

  const pageLayout = props.data?.strapiVenue ? "venue-detail" : pageData?.layout

  return (
    <ParallaxProvider>
      <Header pageLayout={pageLayout} key={"header-wrap"} />
      <AnimatePresence mode="wait">{element}</AnimatePresence>
      <Footer />
    </ParallaxProvider>
  )
}

export function shouldUpdateScroll(prevRouterProps, { location }) {
  window.scrollTo(0, 0)
  const body = document.getElementsByTagName("body")[0]
  body.scrollTop = 0
  return [0, 0]
}
