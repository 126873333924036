import clsx from "clsx"
import React, { useState } from "react"
import { Container } from "react-bootstrap"
import { useDebounce } from "../../hooks/useDebounce"
import useHasScrolled from "../../hooks/useHasScrolled"
import logo from "../../images/bc-logo.svg"
import barsIcon from "../../images/icons/bars-3.svg"
import phoneIcon from "../../images/icons/device-phone-mobile.svg"
import mailIcon from "../../images/icons/envelope.svg"
import BurgerMenu from "../BurgerMenu/BurgerMenu"
import "./Header.scss"
import { Link } from "gatsby"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import useCompanyInfo from "../../hooks/useCompanyInfo"
import { enquireURL } from "../../common/utils/urls"
import { motion, useInView } from "framer-motion"
import { contentTopVariant } from "../../common/animations"

const Header = ({ pageLayout }) => {
  const [showModal, setShowModal] = useState(false)

  const scrolled = useHasScrolled()
  // const debouncedScrolled = useDebounce(scrolled, 100)

  const { isTablet } = useDeviceMedia()

  const { phone } = useCompanyInfo()

  const isTransparent = pageLayout === "venue-detail" && !isTablet

  const MotionLink = motion(Link)
  const ref = React.useRef()
  const isInView = useInView(ref, { once: true })

  return (
    <div
      className={clsx(
        "header-wrap",
        scrolled && "header-scrolled",
        isTransparent && "header-transparent"
      )}
      ref={ref}
      key={"header-wrap"}
    >
      <Container className="header">
        <Link to={"/"} className="header-logo">
          <motion.img
            src={logo}
            alt="logo"
            variants={contentTopVariant}
            initial="initial"
            animate={
              isInView && {
                ...contentTopVariant.animate,
                transition: {
                  ...contentTopVariant.animate.transition,
                  duration: 0.8,
                },
              }
            }
          />
        </Link>
        <motion.div
          className="header-nav"
          variants={contentTopVariant}
          initial="initial"
          animate={{
            ...contentTopVariant.animate,
            transition: {
              ...contentTopVariant.animate.transition,
              delay: 0.3,
            },
          }}
        >
          <Link to={enquireURL} className="nav-item">
            <img src={mailIcon} alt="mail icon" />
            <span>Enquire</span>
          </Link>
          <motion.a
            href={`tel:${phone}`}
            className="nav-item"
            variants={contentTopVariant}
            initial="initial"
            animate={{
              ...contentTopVariant.animate,
              transition: {
                ...contentTopVariant.animate.transition,
                delay: 0.3,
              },
            }}
          >
            <img src={phoneIcon} alt="phone icon" />
            <span>{phone}</span>
          </motion.a>
          <motion.button
            className="nav-item"
            onClick={() => setShowModal(true)}
            variants={contentTopVariant}
            initial="initial"
            animate={{
              ...contentTopVariant.animate,
              transition: {
                ...contentTopVariant.animate.transition,
                delay: 0.5,
              },
            }}
          >
            <img src={barsIcon} alt="bars icon" />
            <span>Explore</span>
          </motion.button>
        </motion.div>
      </Container>
      <BurgerMenu showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

export default Header
