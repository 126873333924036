import React, { useEffect, useRef, useState } from "react"
import { Collapse, Container } from "react-bootstrap"
import downArrow from "../../images/icons/chevron-down.svg"
import "./PopularSearch.scss"
import { graphql, useStaticQuery } from "gatsby"
import CTALink from "../elements/CTALink"

const PopularSearch = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiGlobalModule {
        nodes {
          popular_search {
            column1 {
              ...componentCTAFragment
            }
            column2 {
              ...componentCTAFragment
            }
            column3 {
              ...componentCTAFragment
            }
            column4 {
              ...componentCTAFragment
            }
          }
        }
      }
    }
  `)

  const ref = useRef(null)

  const { column1, column2, column3, column4 } =
    data.allStrapiGlobalModule.nodes[0].popular_search

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        ref.current.scrollIntoView({ behavior: "smooth" })
      }, 200)
    }
  }, [open])

  return (
    <Container className="popular-search-wrap">
      <button onClick={() => setOpen(!open)} className="popular-search-button">
        <span>Popular Searches</span>
        <img
          src={downArrow}
          alt=""
          style={{
            transform: open ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </button>
      <Collapse in={open}>
        {/* dont remove div */}
        <div>
          <div className="popular-links-wrap">
            <div className="popular-links">
              {column1.map(link => (
                <CTALink cta={link} />
              ))}
            </div>
            <div className="popular-links">
              {column2.map(link => (
                <CTALink cta={link} />
              ))}
            </div>
            <div className="popular-links">
              {column3.map(link => (
                <CTALink cta={link} />
              ))}
            </div>
            <div className="popular-links">
              {column4.map(link => (
                <CTALink cta={link} />
              ))}
            </div>
          </div>
        </div>
      </Collapse>
      <div ref={ref}></div>
    </Container>
  )
}

export default PopularSearch
