import React from "react"
import "./Footer.scss"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import instalogo from "../../images/icons/Instagram.svg"
import PopularSearch from "../PopularSearch/PopularSearch"
import useCompanyInfo from "../../hooks/useCompanyInfo"
import {
  cookieURL,
  enquireURL,
  privacyURL,
  termsURL,
} from "../../common/utils/urls"
import { motion, useInView } from "framer-motion"
import { contentVariant } from "../../common/animations"
import CookieConsent from "../CookieConsent/CookieConsent"
import useHasMouseMoved from "../../hooks/useHasMouseMoved"

const Footer = () => {
  const { address, email, insta_handle, instagram_link, phone } =
    useCompanyInfo()

  const ref = React.useRef(null)
  const isInView = useInView(ref, { amount: 0.2, once: true })

  const mouseMoved = useHasMouseMoved()

  if (!mouseMoved) return null

  return (
    <motion.div className="footer-wrap section-p-120" ref={ref}>
      <Container className="footer">
        <motion.div
          className="footer-left"
          // variants={contentVariant}
          // initial="initial"
          // animate={isInView && "animate"}
        >
          <h4 className="title">let's work together</h4>
          <div className="footer-info">
            <a className="phone" href={`tel:${phone}`}>
              {phone}
            </a>
            <Link className="email" to={enquireURL}>
              {email}
            </Link>
            {/* <p className="address">{address}</p> */}
          </div>
        </motion.div>
        <motion.div
          className="footer-right"
          // variants={contentVariant}
          // initial="initial"
          // animate={
          //   isInView && {
          //     ...contentVariant.animate,
          //     transition: {
          //       ...contentVariant.animate.transition,
          //       delay: 0.3,
          //     },
          //   }
          // }
        >
          <div className="insta-section">
            <a href={instagram_link} className="insta-logo">
              <img src={instalogo} alt="insta logo" />
            </a>
            <a href={instagram_link} target="_blank" className="insta-handle">
              {insta_handle}
            </a>
          </div>
          <div className="footer-links">
            <div className="policy-links">
              <Link to={termsURL} className="footer-link">
                Terms
              </Link>
              <span>/</span>
              <Link to={privacyURL} className="footer-link">
                Privacy Policy
              </Link>
              <span>/</span>
              <Link to={cookieURL} className="footer-link">
                Cookie Policy
              </Link>
              <span>/</span>
              <a href="javascript:void(0);" id="open_preferences_center" className="footer-link">Update Cookies Preferences</a>
            </div>
            <p className="copyright footer-link">
              Blank Canvas © 2023. All Rights Reserved
            </p>
            <p className="site-by footer-link">
              Site by{" "}
              <a href="https://starberry.tv/" target="_blank">
                Starberry
              </a>
            </p>
          </div>
        </motion.div>
      </Container>
      <Container>
        <div className="horizontal-divider"></div>
      </Container>
      <PopularSearch />
      <section className="cookie-popup">
        <CookieConsent/>
      </section>
    </motion.div>
  )
}

export default Footer
