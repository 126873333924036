export const layoutVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { ease: "linear", duration: 0.2 },
  },
  exit: {
    opacity: 0,
    transition: { ease: "linear", duration: 0.2 },
  },
}

export const contentVariant = {
  initial: {
    opacity: 0,
    y: 200,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { ease: "easeOut", duration: 0.8 },
  },
  exit: {
    opacity: 0,
    y: -200,
  },
}
export const contentTopVariant = {
  initial: {
    opacity: 0,
    y: -200,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { ease: "easeOut", duration: 0.8 },
  },
  exit: {
    opacity: 0,
    y: 200,
  },
}
