import React from "react"
import clsx from "clsx"
import { Link } from "gatsby"
import { getMenuLink } from "../../common/utils/utils"

const CTALink = ({ onClick, className, cta, children }) => {
  if (!cta) return null

  const { label, custom_link, menu, external_link } = cta

  const linkHref = menu && getMenuLink(menu)

  if (!(linkHref?.to || linkHref?.href || custom_link)) return null

  const isExternal =
    linkHref?.href?.includes("http") ||
    custom_link?.includes("http") ||
    external_link

  return (
    <Link
      className={clsx(className)}
      onClick={onClick}
      {...linkHref}
      {...(isExternal && { target: "_blank" })}
    >
      {children ? children : <span>{label}</span>}
    </Link>
  )
}

export default CTALink
