import { graphql, useStaticQuery } from "gatsby"

const useCompanyInfo = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiSiteConfig {
        nodes {
          strapi_id
          phone
          instagram_link
          insta_handle
          email
          brand_name
          address
        }
      }
    }
  `)

  const { phone, instagram_link, insta_handle, email, brand_name, address } =
    data.allStrapiSiteConfig.nodes[0]

  return { phone, instagram_link, insta_handle, email, brand_name, address }
}

export default useCompanyInfo
